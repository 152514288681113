export default {
	name: "Pergunta",
	props: ["pergunta", "blRelacionada"],
	methods: {
		selecionaRelacionada(id) {
			this.$emit("selecionaRelacionada", id);
		},
		copiar() {
			this.$emit("copiarLinkPergunta", this.$props.pergunta.id);
		}
	}
};